.richTextBlock-wrapper {
  max-width: $max-content-width;
  .rich-text-block {
    padding: 0 $padding 60px $padding;
    @media (min-width: $max-content-width + ($padding * 2)) {
      padding: 0 0 30px;
    }
  }
}

