.simulation {
  background-color: transparent;
  min-height: calc(100vh - 45px);
  //padding-bottom: 60px;
  .introduction {
    min-height: calc(100vh - 106px);
    background: #effafc;
    background-image: url(../../images/watercolour.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    padding-top: 1rem;
  }
  .container {
    max-width: 1050px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    @media (min-width: 1024px) {
      margin-left: 27vw;
    }
    @media (min-width: 1440px) {
      margin-left: 27vw;
    }

    @media (min-width: $max-content-width + 40) {
      padding: $padding 0;
    }
    &.not-found {
      color: #fff;
      a {
        color: $turquoise-blue;
      }
    }
    h1 {
      @include dm-serif-display;
      color: $black-pearl;
      font-size: 44px;
      margin: 0.25em 0 0.4em;
      line-height: 1;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      @include merriweather-regular;
      line-height: 1.7;
      margin-bottom: 1rem;
      margin-top: 2rem;
      @media (min-width: 768px) {
        display: block;
      }
      @media (min-width: $max-content-width) {
        max-width: 75%;
        font-size: 16px;
      }
      p:first-child {
        margin-top: 0;
      }
      blockquote {
        border-top: 4px solid $orange;
        border-bottom: 4px solid $orange;
        display: inline-block;
        padding: 1rem 0 0;
        font-size: 22px;
        font-style: italic;
        font-weight: bold;
        line-height: 1.2;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      p:not(:last-child) {
        padding-bottom: 1rem;
      }
    }
    .simulation-intro-image {
      img {
        width: 50%;
        @media (min-width: 768px) {
          float: right;
          margin-left: 2rem;
          width: 35%;
        }
      }
    }

    .answer-intro {
      @include boston-semibold;
      font-size: 1.75rem;
      position: relative;
      margin-top: 4rem;
      @media (min-width: $max-content-width) {
        font-size: 2rem;
        display: inline-block;
      }
      &:before {
        content: '...';
        @include merriweather-regular;
        color: $orange;
        position: absolute;
        top: -3.5rem;
        left: 0;
        font-size: 2.125rem;
      }
    }
  }
  .button-wrapper {
    margin: 1.5rem 0;
    @media (min-width: $mobile-breakpoint) {
      margin: 2rem 0;
    }
  }
}

.ReactModalPortal {
  .modal.interstitial-modal {
    background-image: url(../../images/modal-watercolour2.jpg);
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    max-height: 90vh;
    .modal-header {
      padding: 1rem;
      position: relative;
    }
    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
      background: none;
      border: none;
    }
    .modal-body {
      padding: 0 1rem 1.5rem;
      overflow-y: auto;
      text-align: center;
      @media (min-width: $mobile-breakpoint) {
        padding: 0 2rem 1.5rem;
        min-width: 470px;
      }
      h1 {
        font-size: 32px;
        @include dm-serif-display;
        text-align: center;
        line-height: 1.2;
      }
    }
    .rich-text {
      font-size: 1.125rem;
      @include boston-regular;
    }
    .interstitial-header {
      font-size: 14px;
      text-transform: uppercase;
      @include boston-bold;
      text-align: center;
    }
    .primary {
      margin: 1rem 0;
      &:hover {
        border: 1px solid $dark-blue;
        background-color: $dark-blue;
        color: $white;
      }
    }
  }
}
