$mobile-breakpoint: 600px;
$max-content-width: 1100px;
$padding: 20px;
$orange: #ffa400;
$black-pearl: #131e29;
$turquoise-blue: #5fd0df;
$dark-turquoise-blue: #5198a7;
$united-way-red: #e1251b;
$dim-grey: #666;
$grey20: #333;
$white: #fff;
$dark-blue: #141E29;
$aqua-blue: #00CFE0;
$border-grey: #E6E6E6;

@mixin content-padding {
  @media (max-width: $max-content-width + $padding * 2 - 1) {
    padding-right: $padding;
    padding-left: $padding;
  }
}

@mixin block-layout {
  max-width: $max-content-width;
  @include content-padding;
  @media (min-width: $max-content-width + $padding * 2) {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin homepage-content-padding {
  padding: 20px;
  @media (min-width: $mobile-breakpoint) {
    padding: 40px 80px;
  }
  @media (min-width: 900px) {
    padding: 80px 160px;
  }
}
