.location-chooser-wrapper {
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-position: left bottom 0;
}
.location-chooser-modal {
  @media (max-width: 470px) {
    padding: 0 10px;
  }
  display: flex;
  align-items: center;
  background-color: rgba(255, 164, 0, 0.6);
  &:hover {
    background-color: rgba(255, 164, 0, 1);
  }
  padding: 0 30px;
  height: 55px;

  .launcher {
    @media (max-width: 470px) {
      font-size: 0.75rem;
    }
    white-space: nowrap;
    color: $black-pearl;
    font-size: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

.location-chooser {
  width: 80%;
  height: auto;
  text-align: center;
  h1 {
    padding-bottom: 0px !important;
    @include title-header;
  }
  h2 {
    margin-top: 1rem;
  }
  p {
    @include boston-regular;
    @media (min-width: $mobile-breakpoint) {
      font-size: 20px;
      line-height: 26px;
    }
  }
  p.small {
    @media (min-width: $mobile-breakpoint) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  select {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .set-location-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 14px;
    @include boston-semibold;
    color: $dark-blue;
    background-color: $white;
    margin: auto;
    white-space: nowrap;
    padding-right: 0;
    border: $aqua-blue 1px solid;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;

    .darkblue-right-arrow {
      background-color: $aqua-blue;
      padding: 1rem;
      width: 100%;
      margin-left: 7px;
    }

    &:hover {
      border: 1px solid $dark-blue;
      .darkblue-right-arrow {
        background-color: $dark-blue;
        transition: 0.2s ease;
  
        #location-arrow {
          fill: $white;
          transition: 0.2s ease;
        }
      }
    }
  }
}

.locations-modal {
  padding-top: 2rem;
  padding-bottom: 1rem;
  .map-pin-icon {
    height: 19px;
  }
  .locations-modal-header {
    font-size: 26px;
    text-decoration: none;
    text-transform: uppercase;
  }
  select {
    margin-bottom: 1rem;
  }
  .header-location-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .set-location-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 14px;
    @include boston-semibold;
    color: $dark-blue;
    background-color: $white;
    margin: auto;
    white-space: nowrap;
    padding-right: 0;
    border: $aqua-blue 1px solid;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;

    .darkblue-right-arrow {
      background-color: $aqua-blue;
      padding: 1rem;
      width: 100%;
      margin-left: 7px;
    }

    &:hover {
      border: 1px solid $dark-blue;
      .darkblue-right-arrow {
        background-color: $dark-blue;
        transition: 0.2s ease;
  
        #location-arrow {
          fill: $white;
          transition: 0.2s ease;
        }
      }
    }
  }
}
