@font-face {
  font-family: 'Boston';
  src: url('../static/fonts/boston-bold/boston-bold.eot');
  src: url('../static/fonts/boston-bold/boston-bold.woff') format('woff'),
    url('../static/fonts/boston-bold/boston-bold.svg') format('svg');
  font-weight: 700;
}

@font-face {
  font-family: 'Boston';
  src: url('../static/fonts/boston-semibold/boston-semibold.eot');
  src: url('../static/fonts/boston-semibold/boston-semibold.woff')
      format('woff'),
    url('../static/fonts/boston-semibold/boston-semibold.svg') format('svg');
  font-weight: 600;
}

@font-face {
  font-family: 'Boston';
  src: url('../static/fonts/boston-regular/boston-regular.eot');
  src: url('../static/fonts/boston-regular/boston-regular.woff') format('woff'),
    url('../static/fonts/boston-regular/boston-regular.svg') format('svg');
  font-weight: 400;
}

@font-face {
  font-family: 'Boston';
  src: url('../static/fonts/boston-black/boston-black.eot');
  src: url('../static/fonts/boston-black/boston-black.woff') format('woff'),
    url('../static/fonts/boston-black/boston-black.svg') format('svg');
  font-weight: 900;
}

@mixin dm-serif-display {
  font-family: 'DM Serif Display', serif;
  font-weight: normal;
}

@mixin boston-bold {
  font-family: 'Boston', sans-serif;
  font-weight: 700;
}

@mixin boston-semibold {
  font-family: 'Boston', sans-serif;
  font-weight: 600;
}

@mixin boston-regular {
  font-family: 'Boston', sans-serif;
  font-weight: 400;
}

@mixin boston-light {
  font-family: 'Boston', sans-serif;
  font-weight: 100;
}

@mixin merriweather-regular {
  font-family: 'Merriweather', sans-serif;
  font-weight: 400;
}

@mixin merriweather-bold {
  font-family: 'Merriweather', sans-serif;
  font-weight: 700;
}

@mixin boston-black {
  font-family: 'Boston', sans-serif;
  font-weight: 900;
}

@mixin content-block-header($color: $turquoise-blue) {
  @include boston-semibold;
  color: $color;
  font-size: 21px;
  line-height: 22px;
  margin: 0 0 10px;
  @media (min-width: $mobile-breakpoint) {
    font-size: 30px;
    line-height: 26px;
    margin: 0 0 15px;
  }
}

@mixin content-block-paragraph($color: #fff) {
  @include boston-semibold;
  color: $color;
  margin: 0 0 1.3em;
  font-size: 17px;
  line-height: 22px;
  @media (min-width: $mobile-breakpoint) {
    font-size: 20px;
    line-height: 26px;
  }
}

@mixin title-header {
  @include dm-serif-display;
  color: $black-pearl;
  font-size: 30px;
  line-height: 33px;
  margin: 0 0 20px;
  @media (min-width: $mobile-breakpoint) {
    font-size: 60px;
    line-height: 60px;
    padding-bottom: 40px;
    margin: 0 0 30px;
  }
}

@mixin page-title($ribbon-style: false) {
  @include boston-bold;
  color: $orange;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  @media (min-width: $mobile-breakpoint) {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  @if $ribbon-style {
    color: $orange;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: $mobile-breakpoint) {
      padding: 4px 15px;

      display: inline-block;
      position: relative;
      color: $black-pearl;
      background-color: $orange;

      font-size: 22px;
      line-height: 38px;
      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent $orange;
        right: -10px;
        border-width: 23px 0 23px 10px;
      }
    }
  }
}

body {
  @include boston-semibold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.33;
}
