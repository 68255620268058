button,
.button {
  cursor: pointer;
  text-decoration: none;
  &.outline,
  &.primary,
  &.secondary {
    padding: 1rem;
    border-radius: 0.25rem;
    @include boston-semibold;
    font-size: 0.875rem;
    text-transform: uppercase;
    border: 1px solid $turquoise-blue;
    @media (min-width: $mobile-breakpoint) {
      font-size: 1.063rem;
      padding: 0.85rem 1.75rem;
    }
  }
  &.outline {
    background-color: #FFFFFF;
    color: #B0B0B0;
    cursor: default;

    .darkblue-right-arrow {
      background-color: #E6E6E6;
    }

    &.has-arrow {
      .arrow {
        background-color: #E6E6E6;
        svg {
          path {
            fill: #B0B0B0;
          }
        }
      }
    }

    svg {
      path {
        fill: #B0B0B0;
      }
    }
  }
  &.primary {
    background: #fff;
    color: $black-pearl;
    cursor: pointer;
    &.has-arrow {
      .arrow {
        background: $turquoise-blue;
        svg {
          path {
            fill: $black-pearl;
          }
        }
      }
    }

    &.has-arrow:hover {
      border: 1px solid $dark-blue;
      .arrow {
        background: $black-pearl;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &.secondary {
    background: $turquoise-blue;
    color: $black-pearl;

    &:hover {
      transition: all 0.3s;
      background: $black-pearl;
      color: #fff;
      border: 1px solid $dark-blue;
    }
  }
  &.continue {
    @media (min-width: $mobile-breakpoint) {
      margin-left: 24px;
    }
  }
  &.has-arrow {
    padding: 0 0 0 1.5rem;
    display: flex;
    align-items: center;
    .arrow {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 42px;
      margin-left: 1.5rem;
      @media (min-width: $mobile-breakpoint) {
        height: 48px;
        width: 48px;
        margin-left: 1.75rem;
      }
    }
  }
  &.thick-border {
    border-width: 2px;

    &:hover {
      border: 2px solid $dark-blue;
    }
  }
}

.cta-block {
  box-sizing: border-box;

  .begin-story-block {

    // THIS BLOCK IS THE BUTTON ON THE SELECT A YOUTH TO SUPPORT PAGE
    &.youth {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      //height: 3rem;
      box-sizing: border-box;

      @media (min-width: 768px) {
        margin-top: 1rem;
      }

      .begin-story-button {
        padding: 1rem;
        border: $aqua-blue 1px solid;
        border-radius: 4px 0 0 4px;
        width: 100%;
        margin-bottom: 1rem;
        box-sizing: border-box;

        @media (min-width: 768px) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .darkblue-right-arrow {
        box-sizing: border-box;

        &.youth {
          margin-left: 0;
          border-radius: 0 4px 4px 0;
          box-sizing: border-box;
          width: 25%;
        }
      }
    }

    // FOUND ON THE ROLE SELECTION PAGE
    .begin-story-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 0 0 14px;
      @include boston-semibold;
      color: $dark-blue;
      background-color: $white;
      margin-top: 1rem;
      margin-bottom: 2rem;
      white-space: nowrap;
      padding-right: 0;
      border: $aqua-blue 1px solid;
      border-radius: 4px;
      text-transform: uppercase;
      cursor: pointer;
      box-sizing: border-box;

      &.outline {
        background-color: #FFFFFF;
        border: #B0B0B0 1px solid;
        color: #B0B0B0;
        cursor: default;

        .darkblue-right-arrow {
          background-color: #E6E6E6;
        }

        svg {
          path {
            fill: #B0B0B0;
          }
        }
      }

      @media (min-width: 768px) {
        margin-top: 2rem;
      }
    }

    .darkblue-right-arrow {
      background-color: $aqua-blue;
      padding: 1rem;
      width: 100%;
      margin-left: 7px;
    }

    .review-supportive-skills-button {
      position:relative;
      @include boston-semibold;
      margin-left: 2rem;
      padding: 1rem;
      background-color: $aqua-blue;
      border: 1px solid $aqua-blue;
      text-transform: uppercase;
      color: $dark-blue;
      border-radius: 4px;

      &.instructions {
        margin-left: 0;

        @media (min-width: 768px) {
          margin-left: 2rem;
        }

        &:hover {
          background-color: $dark-blue;
          color: $white;
          border: 1px solid $dark-blue;
        }
      }
    }

    &.instructions {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .begin-story-button:hover {
        border: 1px solid $dark-blue;
        .darkblue-right-arrow {
          background-color: $dark-blue;
          transition: 0.2s ease;
    
          #instructions-arrow {
            fill: $white;
            transition: 0.2s ease;
          }
        }
      }

      @media (min-width: 768px) {
        flex-direction: row;
        margin-top: -1rem;
      }
    }
  }
}

.simulation-audio {
  .audio-play {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    border: none;
    gap: 7px;

    .audio-icon-wrapper {
      display: flex; // not sure why display:flex works in this way, but it centers the audio button icon

      svg {
        width: auto;
        height: auto;
      }
    }

    p {
      @include boston-bold;
      color: #000;
      font-size: 16px;
      text-decoration-line: underline;
    }
  }
}