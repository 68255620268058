.watermark-background {
  background-image: url('../../images/splash-bottom.jpg');
  background-repeat: no-repeat;
  background-size: 65% auto;
  transition: background-position 0.5s cubic-bezier(0, 1, 0.5, 1);
  @media (max-width: $mobile-breakpoint - 1) {
    background-position: left bottom !important;
  }
  .steps-block {
    @include block-layout;
    .steps-content {
      @media (min-width: $mobile-breakpoint) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      @media (min-width: 900px) {
        padding-top: 80px;
        padding-bottom: 80px;
      }
      padding-bottom: 50px;
      padding-top: 25px;

      @media (min-width: $mobile-breakpoint) {
        display: flex;
        padding-top: 50px;
      }
    }

    .social-block {
      position: relative;
      top: -25px;
    }

    .rich-text {
      max-width: 500px;
    }

    .button {
      display: inline-block;
    }

    .steps-block-left {
      @media (min-width: $mobile-breakpoint) {
        width: 55%;
        padding-right: 20px;
      }

      .rich-text {
        margin-bottom: 30px;
      }

      .small-gear {
        display: block;
        background: #fff;
        width: 57px;
        margin: 0 auto -13px;
        position: relative;

        img {
          display: block;
          margin: 0 auto;
        }

        @media (min-width: $mobile-breakpoint) {
          display: none;
        }
      }

      h2 {
        @include boston-semibold;
        font-size: 25px;
        line-height: 38px;
        margin-top: 0;
        margin-bottom: 0.5em;
        padding: 15px 0 12px;
        border-top: 1px dashed $turquoise-blue;
        border-bottom: 1px dashed $turquoise-blue;
        text-align: center;
        color: $black-pearl;
        @media (min-width: $mobile-breakpoint) {
          text-align: left;
          border: none;
          margin-top: 0;
          padding: 0;
          font-size: 46px;
          line-height: 38px;
        }
      }

      p {
        @include boston-regular;
        color: $dim-grey;
        font-size: 17px;
        line-height: 22px;
        @media (min-width: $mobile-breakpoint) {
          color: $grey20;
          font-size: 22px;
          line-height: 26px;
        }
      }

      .button {
        display: none;
        @media (min-width: $mobile-breakpoint) {
          display: inline-block;
        }
        &:hover {
          transition: all .3s;
          background: #131e29;
          color: #fff;
        }
      }
    }

    .steps-block-right {
      text-align: center;
      @media (min-width: $mobile-breakpoint) {
        width: 45%;
        text-align: left;
      }

      .step {
        display: flex;
        text-align: left;

        &:not(:last-child) {
          .rich-text {
            &:before {
              display: block;
              content: '';
              height: 100%;
              width: 1px;
              position: absolute;
              background-color: $turquoise-blue;
              left: -14px;
              @media (min-width: $mobile-breakpoint) {
                background-color: $orange;
                left: -22.5px;
              }
            }
          }
        }

        .step-number {
          @include boston-black;
          border-radius: 50%;
          flex-shrink: 0;
          flex-grow: 0;
          text-align: center;
          position: relative;
          z-index: 1;
          color: $black-pearl;
          background-color: $turquoise-blue;
          font-size: 16px;
          width: 28px;
          height: 28px;
          line-height: 28px;
          @media (min-width: $mobile-breakpoint) {
            background-color: $orange;
            font-size: 25px;
            width: 45px;
            height: 45px;
            line-height: 45px;
          }
        }

        .rich-text {
          padding-left: 10px;
          padding-bottom: 20px;
          position: relative;
          min-height: 28px;
          @media (min-width: $mobile-breakpoint) {
            min-height: 45px;
            padding-left: 35px;
          }

          h3 {
            @include boston-bold;
            font-size: 21px;
            line-height: 22px;
            color: $grey20;
            margin: 0.1em 0;
            @media (min-width: $mobile-breakpoint) {
              color: $dim-grey;
              margin: 0.3em 0;
              font-size: 24px;
              line-height: 26px;
            }
          }

          p {
            @include boston-regular;
            margin: 0;
            font-size: 15px;
            line-height: 22px;
            color: $grey20;
            @media (min-width: $mobile-breakpoint) {
              color: $dim-grey;
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      .button {
        display: inline-block;
        @media (min-width: $mobile-breakpoint) {
          display: none;
        }
      }
    }
  }
}

.infoBlock-wrapper + .stepsBlock-wrapper {
  .gear {
    display: none;
  }
  @media (min-width: 900px) {
    .steps-block {
      position: relative;
      z-index: 0;
      .gear {
        pointer-events: none;
        position: absolute;
        display: block;
        content: '';
        width: 510px;
        height: 510px;
        background-image: url('../../images/gear.svg');
        background-repeat: no-repeat;
        background-size: contain;
        top: -190px;
        left: -25%;
        z-index: -1;
        transition: transform 0.4s cubic-bezier(0, 0, 0, 1) 0s;
      }
    }
  }
}

.infoBlock-wrapper {
  .down-arrow {
    display: block;
    margin: -37px auto 0;
    position: relative;
    @media (min-width: $mobile-breakpoint) {
      display: none;
    }
  }
}
