.progress-bar {
  width: 90%;
  @media (min-width: 768px) {
    width: 95%;
  }
  @media (min-width: 1024px) {
    position: fixed;
    top: 0;
    height: 100vw;
    z-index: 50;
    width: 25vw;
  }
  @media (min-width: 1440px) {
    width: 25vw;
  }
  
  @media (min-width: $mobile-breakpoint) {
    //background: linear-gradient(90deg, #22424d 50%, #2a5460 50%);
  }
  .container {
    background: #2a5460;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    //width: 90%;
  }
  .progress-bar-left {
    padding: 12px;
    color: $turquoise-blue;
    text-transform: uppercase;
    display: block;
    font-size: 14px;
  }
  .progress-bar-right {
    background: #2a5460;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #fff;
    text-transform: uppercase;
    padding: 12px;

    .progress-bar-text {
      font-size: 0.875rem;
    }
  }

  .completed-questions {
    display: flex;
    position: relative;
    background: $turquoise-blue;
    width: 100vw;
    height: 50px;
    z-index: 1;
    @media (min-width: 1024px) {
      height: 100rem;
      width: 25vw;
    }
    @media (min-width: 1440px) {
      width: 25vw;
    }

    ul {
      display: flex;
      flex-direction: row;
      gap: 7px;
      list-style-type: none;
      overflow-x: auto;
      width: 100%;
      
      @media (min-width: 1024px) {
        flex-direction: column;
        padding-top: 1.5rem;
        margin-left: 2rem;
        overflow-y: auto;
        height: 90vh;
      }

      li {
        padding: 0 4px;
        position: relative;
        @media (min-width: 1024px) {
          margin-top: 10px;
        }

        p {
          white-space: nowrap;
        }
      }

      li:first-child:after {
        @media (min-width: 1024px) {
          top: 15px;
        }
      }

      li:after {
        position: absolute;
        display: block;
        content: "";
        left: -23px;
        top: 20px;
        border-bottom: 1px solid #FF9F01;
        width: 33px;
        z-index: 2;

        @media (min-width: 1024px) {
          top: 0;
          left: 8px;
          height: 45px;
          border-bottom: none;
          border-left: 1px solid #FF9F01;
          bottom: 0;
        }
      }

      li:last-child:after {
        @media (min-width: 1024px) {
          display: none;
        }
      }

      .completed-question {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 90px;
        text-align: center;
        gap: 7px;
        color: #000;
        width: fit-content;
        margin: 5px;

        p {
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
          padding-right: 7px;
        }

        @media (min-width: 1024px) {
          left: 10px;
          gap: 20px;

          p {
            font-size: 18px;
          }
        }

        &.current {
          background: $white;
          color: #131E29;
          margin: 5px;
          left: 0;

          p {
            font-weight: bold;
          }

          @media (min-width: 1024px) {
            padding: 5px;
            left: 0px;

            p {
              margin-left: -12px
            }
          }
        }
      }
    }
  }
}
