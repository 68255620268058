.question-component {
  min-height: calc(100vh - 106px);
  position: relative;

  .gradient-wrapper {
    padding-top: 2rem;
    position: relative;
    background-color: #effafc;
    background-image: url(../../images/watercolour.jpg);
    height: 100%;
    background-repeat: no-repeat;
    background-position: top right;
    @media (min-width: $max-content-width) {
      padding-top: 0;
      padding-bottom: 2rem;
    }
    &.fade-enter-done:before {
      content: '';
      display: block;
      height: 45%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -15rem;
      background-image: url(../../images/splash-bottom.jpg);
      background-color: #effafc;
      background-position: bottom left;
      background-repeat: no-repeat;
      background-blend-mode: multiply;
    }
  }
  .ribbon {
    background: $orange;
    @include merriweather-bold;
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin-top: 0;
    position: relative;
    @media (min-width: $max-content-width) {
      font-size: 1.25rem;
      margin-bottom: 2rem;
      padding: 0.5rem 3.5rem 0.5rem 2rem;
    }
    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: 0;
      right: -10px;
      border-top: 18.5px solid transparent;
      border-bottom: 18.5px solid transparent;
      border-left: 10px solid $orange;
      @media (min-width: $max-content-width) {
        border-top: 21px solid transparent;
        border-bottom: 21px solid transparent;
      }
    }
  }

  .illustration-image {
    margin-top: 1rem;
    img {
      width: 40%;
      margin: auto;
      @media (min-width: 768px) {
        position: relative;
        float: right;
        margin: 0 7.5rem 7px 0;
        width: 25%;
        ;
      }
    }
  }

  .answer-intro {
    @include boston-semibold;
    font-size: 1.75rem;
    position: relative;
    margin-top: 4rem;
    @media (min-width: $max-content-width) {
      font-size: 2rem;
      display: inline-block;
    }
    &:before {
      content: '...';
      @include merriweather-regular;
      background-color: transparent;
      color: $orange;
      position: absolute;
      top: -3.5rem;
      left: 0;
      font-size: 2.125rem;
    }
  }

  .instruction {
    @include boston-bold;
    color: #23434E;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.28px;
    padding-right: 20%;
    @media (min-width: 414px) {
      padding-right: 30%;
      font-size: 20px;
    }
    @media (min-width: $max-content-width) {
      margin-bottom: 2rem;
    }
  }

  .responses {
    background-color: #effafc;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-image: url(../../images/splash-bottom.jpg);
      background-color: #effafc;
      background-position: bottom left;
      background-repeat: no-repeat;
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      left: 0;
      
      @media (min-width: 1024px) {
        left: 25vw;
        width: 75%;
      }

      @media (min-width: 1440px) {
        left: 25vw;
      }
    }
    &:after {
      right: 0;
      border-width: 0 0 21px 50vw;
    }
    .container {
      position: relative;
      margin-top: 0;
      padding-bottom: 5rem;
      @media (min-width: 1024px) {
        margin-top: -5rem;
        padding-bottom: 25rem;
      }
    }
    
    .responses-wrapper {
      display: flex;
      flex-direction: column;
      gap: 7px;

      @media (min-width: 768px) {
        width: 75%;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
        width: 100%;
      }
    }
    .response {
      text-align: left;
      @include boston-semibold;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.16px;
      line-height: 1.6;
      margin-bottom: 1rem;
      border-radius: 4px;
      border: 1px solid #F5F5F5;
      background: #FFF;
      box-shadow: 0px 0px 1px 0px #5FD0DF;
      transition: box-shadow 0.3s;
      cursor: pointer;
      &:hover {
        border-color: $turquoise-blue;
      }
      &.active {
        .response-checkmark {
          background: $orange;

          .checkmark-svg path {
            stroke: #000;
          }
        }
      }
      @media (min-width: $max-content-width) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0;
        font-size: 1rem;
        height: fit-content;
      }

      .response-checkmark {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 56px;
        flex-shrink: 0;
        border-radius: 4px 4px 0px 0px;
        background: $turquoise-blue;

        .checkmark-svg {
          width: 30px;
          margin-left: 14px;

          path {
            stroke: #B0B0B0; 
          };
        }
      }

      .answer {
        padding: 0.75rem 1rem;
      }

      .answer-audio {
        padding: 0.75rem 1rem;
        margin-left: auto;
      }
    }
    .next-button {
      margin: 1rem 0;
      @media (min-width: $max-content-width) {
        right: $padding;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
      }
      @media (min-width: $max-content-width + ($padding * 2)) {
        right: 5.5rem;
      }
      button {
        border-radius: 4px;
        border: 1px solid #5FD0DF;
        background: #FFF;
      }
      button:not(.outline):hover {
        border: 1px solid $dark-blue;
      }
      .warning {
        color: $orange;
        text-align: center;
        @media (min-width: $max-content-width) {
          text-align: right;
          margin: 0 1rem;
        }
      }
    }
  }
  .ending {
    position: relative;
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 4rem;
    }
    .final-outcome-container {
      @include merriweather-regular;
      line-height: 1.7;
      margin-bottom: 1rem;
      margin-right: 1rem;
      @media (min-width: 1024px) {
        font-size: 1.125rem;
        max-width: 50%;
      }
      h1 {
        color: #000;
        @include dm-serif-display;
      }
      .ending-content {
        margin-top: 1rem;
        p:not(:last-child) {
          padding-bottom: 1rem;
        }
      }
      .learn-more-container {
        display: inline-block;
        margin-bottom: 5rem;
        width: 100%;
        @media (min-width: 1024px) {
          width: 50%;
          align-items: flex-start;
        } 
        .learn-more {
          display: flex;
          flex-direction: column;
          @include boston-regular;
          margin-top: 5rem;
          margin-bottom: 0.5rem;
          color: #131E29;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 21.28px; /* 133% */
          text-transform: uppercase;
          max-width: fit-content;
        }
        .learn-more:before {
          content: "...";
          font-family: Merriweather,sans-serif;
          font-weight: 400;
          color: #ffa400;
          position: relative;
          top: 6rem;
          font-size: 2.125rem;
        }
        .arrow-container {
          text-align: center;
          .darkblue-arrow-down {
            transform: rotate(90deg);
            margin-top: 0.5rem;
          }
        }
      }
      .feedback-content {
        margin-top: 2.5rem;
      }
    }
    h2 {
      color: $black-pearl;
      opacity: 0.7;
      font-size: 2rem;
      margin: 1em 0 0.4em;
      line-height: 1;
      @media (min-width: $mobile-breakpoint) {
        font-size: 3.5rem;
      }
    }
    .button {
      display: inline-flex;
    }
    .content-container {
      @media (min-width: 1024px) {
        margin-left: 1rem;
        margin-top: 15rem;
      }
      .illustration-image {
        text-align: center;
        img {
          width: 45%;
          @media (min-width: 768px) {
            position: relative;
            float: none;
            width: 30%;
            margin: auto;
          }
          @media (min-width: 1440px) {
            width: 70%;
          }
        }
      }
      .final-outcome-natural-supports-description {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        @media (min-width: 1024px) {
          margin-top: 7rem;
        }
        .description-header {
          // width: 452px;
          // height: 123px;
          padding: 3rem;
          font-weight: bolder;
          line-height: 36px;
          border-radius: 20px 20px 0px 0px;
          background: #00D3E3;
          h2 {
            @include dm-serif-display;
            color: #141E29;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            text-align: left;
            margin: 0;
          }
        }
        .description-text {
          @include boston-regular;
          background-color: $white;
          border-radius: 0px 0px 20px 20px;
          padding: 1rem 3rem 3rem 3rem;
          color: #000;

          .natural-supports-audio {
            margin-bottom: 1rem;
            margin-top: 1rem;
            .simulation-audio {
              .audio-play {
                align-items: flex-start;
              }
            }
          }
        }
      }
    }
    div.content-container.p {
      font-size: 16px;
    }
  }
}
