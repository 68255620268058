.page {
  .info-block {
    display: flex;
    flex-direction: column;
    @media (min-width: $mobile-breakpoint) {
      flex-direction: row;
    }
    .rich-text {
      text-align: center;
      &:first-child {
        @include homepage-content-padding;
        width: 100%;
        background-color: #000;
        @media (min-width: $mobile-breakpoint) {
          display: inline-block;
          width: 60%;
          text-align: left;
          padding-right: 50px;
          padding-left: 20px;
        }
        @media (min-width: 900px) {
          padding-right: 50px;
          padding-left: 20px;
        }
        @media (min-width: $max-content-width + ($padding * 2)) {
          padding-left: calc((100vw - #{$max-content-width}) / 2);
        }
        h2 {
          @include page-title;
        }
        h3 {
          color: $turquoise-blue;
          @include dm-serif-display;
          font-size: 31px;
          line-height: 34px;
          @media (min-width: $mobile-breakpoint) {
            font-size: 42px;
            line-height: 45px;
            margin: 0.5em 0;
          }
          @media (min-width: 1000px) {
            font-size: 55px;
            line-height: 60px;
          }
        }
        p {
          @include boston-regular;
          color: #fff;
          font-size: 17px;
          line-height: 22px;
          text-align: left;
          @media (min-width: $mobile-breakpoint) {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
      &:last-child {
        width: 100%;
        background-color: rgba(95, 208, 223, 0.1);
        @include homepage-content-padding;
        @media (min-width: $mobile-breakpoint) {
          display: inline-block;
          text-align: left;
          width: 40%;
          padding: 40px 30px 40px 50px;
          background-color: $turquoise-blue;
        }
        @media (min-width: 900px) {
          padding: 80px 20px 80px 50px;
        }
        @media (min-width: $max-content-width + ($padding * 2)) {
          padding-right: calc((100vw - #{$max-content-width}) / 2);
        }
        h3 {
          @include boston-bold;
          color: $grey20;
          margin-top: 0;
          font-size: 21px;
          line-height: 22px;
          @media (min-width: $mobile-breakpoint) {
            margin-bottom: 0.5em;
            color: $black-pearl;
            font-size: 33px;
            line-height: 34px;
            margin-top: 82px;
          }
        }
        p {
          @include boston-regular;
          font-size: 21px;
          line-height: 22px;
          color: $black-pearl;
          @media (min-width: 768px) {
            font-size: 31px;
            line-height: 34px;
            color: $black-pearl;
          }
        }
      }
      a {
        color: $black-pearl;
      }
    }
    .natural-supports-info-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: $turquoise-blue;
      @media (min-width: 768px) {
        text-align: left;
        width: 40%;
      }
      @media (min-width: 768px) {
        align-items: flex-start;
      }
      .rich-text {
        background-color: $turquoise-blue;
        @media (min-width: $mobile-breakpoint) {
          text-align: left;
          width: 100%;
          background-color: $turquoise-blue;
        }
        @media (min-width: 1140px) {
          padding-left: 40px;
          padding-right: 40px;
          padding-bottom: 30px;
        }
        h3 {
          @include boston-bold;
          text-align: center;
          color: $black-pearl;
          margin-top: 0;
          font-size: 21px;
          line-height: 22px;
          @media (min-width: $mobile-breakpoint) {
            margin-bottom: 0.5em;
            color: $black-pearl;
            font-size: 33px;
            line-height: 34px;
            margin-top: 82px;
          }
          @media (min-width: 1140px) {
            text-align: left;
          }
        }
        p {
          @include boston-regular;
          margin-top: 14px;
          font-size: 16px;
          line-height: 22px;
          color: $black-pearl;
          text-align: center;
          @media (min-width: $mobile-breakpoint) {
            font-size: 28px;
            line-height: 34px;
            color: $black-pearl;
          }
          @media (min-width: 1140px) {
            text-align: left;
          }
        }
      }
      .natural-supports-btn {
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 60%;
        text-align: center;

        @media (min-width: 768px) {
          margin-left: 40px;
        }

        &:hover {
          transition: all .3s;
          background: #131e29;
          color: #fff;
        }
      }
    }
  }
  .heroBlock-wrapper + .infoBlock-wrapper {
    .info-block {
      position: relative;
      top: -20px;
      @media (max-width: $mobile-breakpoint - 1px) {
        &:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 40px 0 0 $mobile-breakpoint / 2;
          border-color: transparent transparent transparent #000;
          display: block;
          content: '';
          position: absolute;
          top: -40px;
        }
        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 40px 0 0 $mobile-breakpoint / 2;
          border-color: transparent transparent transparent #000;
          display: block;
          content: '';
          position: absolute;
          right: 0;
          transform: rotateY(-180deg);
          top: -40px;
        }
      }
    }
  }
}
