.App-header {
  background: #e6e6e6;
  height: 55px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .hamburger {
    background: none;
    border: none;
    flex: 0 0 70px;
    height: 55px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1265px) {
      width: 90px;
    }
    .st0 {
      fill: $black-pearl;
    }
  }
  .container {
    width: $max-content-width;
    @media (max-width: 470px) {
      margin: 0 10px;
    }
    margin: 0 20px;
  }
  .main-link {
    text-decoration: none;
    color: $black-pearl;
    display: inline-block;
    padding: 1rem 0;
    h1 {
      white-space: nowrap;
      margin: 0;
      @media (max-width: 470px) {
        font-size: 0.875rem;
      }
      font-size: 1.125rem;
      text-transform: uppercase;
    }
  }
  .donate-link {
    display: none;
    height: 55px;
    background: rgba(95, 208, 223, 0.6);
    padding: 0 3rem;
    color: $black-pearl;
    font-size: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      background: $turquoise-blue;
    }
    white-space: nowrap;
    @media (min-width: $max-content-width) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.nav-popout {
  background: $black-pearl;
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  width: 100%;
  max-width: 500px;
  z-index: 1000;
  padding: 0 20px;
  transition: left .5s ease;
  &.open {
    left: 0;
  }
  .nav-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .close-nav {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    position: absolute;
    left: 0;
    bottom: 3px;
    @media (min-width: $mobile-breakpoint) {
      padding: 0.5rem 1.5rem;
    }
    svg {
      cursor: pointer;
      &:hover path {
        fill: $turquoise-blue;
        transition: all 0.3s;
      }
    }
  }
  .main-link {
    text-transform: uppercase;
    color: $turquoise-blue;
    text-decoration: none;
    padding: 1rem;
    display: inline-block;
    @include boston-bold;
    @media (min-width: $mobile-breakpoint) {
      font-size: 1.125rem;
    }
    &:hover {
      color: #fff;
    }
  }
  .header-links {
    border-top: 1px dashed rgba(95, 208, 223, 0.4);
    .link {
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      color: #999;
      border-bottom: 1px dashed rgba(95, 208, 223, 0.4);
      padding: 1rem 2.5rem 1rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @media (min-width: $mobile-breakpoint) {
        font-size: 1.3rem;
        padding: 1.25rem 3rem 1.25rem 1.5rem;
      }
      &:hover, &.active {
        color: #fff;
      }
      &:after {
        content: url(../images/right-arrow.svg);
        position: absolute;
        right: 1rem;
        top: 1rem;
        @media (min-width: $mobile-breakpoint) {
          right: 1.5rem;
          top: 1.35rem;
        }
      }
    }
  }
}
