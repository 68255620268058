$buttonHeaderHeight: 45px; // height of the back/exit buttons and header
$buttonLgWidth: 120px; // width of the back/exit buttons on desktop

.simulation-header {
  height: $buttonHeaderHeight;
  position: relative;
  display: flex;
  align-content: center;
  @media (min-width: 1330px) {
    display: block;
  }
  .container {
    height: $buttonHeaderHeight;
    display: flex;
    flex-direction: row;
    width: 100%;

    .role-concern {
      display: none;
      @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        flex-grow: 2;
        justify-content: center;
      }
      
      @media (min-width: 1330px) {
        margin: 0 auto;
        max-width: $max-content-width;
        padding: 0;
      }
    }
  }
  p {
    text-transform: uppercase;
    font-size: 0.875rem;
    margin: 0;
  }
  .back-button,
  .exit-button {
    width: $buttonHeaderHeight;
    height: $buttonHeaderHeight;
    border: none;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    @include boston-semibold;
    text-transform: uppercase;
    @media (min-width: 1330px) {
      position: absolute;
    }

    .arrow {
      svg {
        display: block;
      }
    }

    svg {
      display: none;
      @media (min-width: 1024px) {
        display: block;
      }
    }
  }
  .back-button {
    @media (min-width: 1330px) {
      left: 0;
      width: $buttonLgWidth;
    }
    .text {
      @media (max-width: 1330px - 1) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
      }
      margin-left: 0.5rem;
      font-size: 0.875rem;
    }
    .arrow {
      background: $turquoise-blue;
      width: 23px;
      height: 23px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .exit-button {
    flex-direction: column;
    font-size: 0.75rem;
    margin-left: auto;
    @include boston-bold;
    svg {
      margin-bottom: 0.2rem;
    }
    @media (min-width: 1330px) {
      right: 0;
      top: 0;
      width: $buttonLgWidth;
      flex-direction: row;
      font-size: 0.875rem;
      svg {
        margin: 0 0.5rem 0 0;
      }
    }
  }
}

// modal styles
.ReactModalPortal {
  .modal.exit-modal {
    max-width: 340px;
    .modal-header {
      background: $orange;
      padding: 1rem;
      position: relative;
      h2 {
        text-align: center;
        font-size: 1.75rem;
        margin: 20px;
        @include boston-semibold;
      }
    }
    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
      background: none;
      border: none;
    }
    .modal-body {
      padding: 1.5rem;
      text-align: center;
      @include boston-regular;
      p {
        margin-top: 0;
        font-size: 1.063rem;
      }
      .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        button, a {
          margin: 0.5rem;
        }
        a {
          @extend button.primary;
          text-decoration: none;
          line-height: 1.15;
        }
        .primary:hover {
          border: 1px solid $dark-blue;
          background-color: $dark-blue;
          color: $white;
        }
      }
    }
  }
}

