.page {
  .resourcesBlock-wrapper {
    background-color: $turquoise-blue;

    .resources-block {
      @include block-layout;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (min-width: 635px) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .rich-text {
        max-width: 400px;
        padding-right: 40px;
        display: inline-block;
        vertical-align: top;

        h2 {
          @include content-block-header;
          color: $black-pearl;
        }

        p {
          @include content-block-paragraph;
          color: $black-pearl;
        }

        a {
          color: $black-pearl;
        }
      }

      .resources {
        display: inline-block;
        vertical-align: top;
        max-width: 700px;
        padding-bottom: 20px;
        @media (min-width: 635px) {
          padding-bottom: 50px;
        }

        .resources-left,
        .resources-right {
          display: block;

          .resource {
            padding: 10px 0;
            border-top: 1px dashed $black-pearl;
            @media (min-width: 635px) {
              padding: 0;
              border-top: none;
            }

            ul {
              width: 100%;
              list-style: none;
              padding-left: 0;
            }

            li {
              margin-bottom: 16px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          @media (min-width: 635px) {
            display: inline-block;
            vertical-align: top;
          }
        }

        .resources-left {
          @media (min-width: 635px) {
            width: 70%;
          }

          .resource {
            ul {
              display: flex;
              flex-wrap: wrap;
            }

            li {
              margin-right: 16px;
              width: 100%;

              @media (min-width: 718px) {
                max-width: 221px;
                width: 50%;
              }

              &:first-child {
                flex-basis: 100%;
                max-width: none;
              }
            }
          }
        }

        .resources-right {
          @media (min-width: 635px) {
            padding-left: 30px;
            width: 30%;
            border-left: 1px dashed $black-pearl;
            .resource:not(:last-child) {
              padding-bottom: 10px;
              margin-bottom: 10px;
              border-bottom: 1px dashed $black-pearl;
            }
          }
        }

        .resource {
          display: flex;
          flex-direction: column;
          @media (min-width: 635px) {
            display: inline-flex;
          }

          h3 {
            @include boston-black;
            margin: 0;
            color: $black-pearl;
            font-size: 17px;
            line-height: 30px;
            @media (min-width: 635px) {
              font-size: 22px;
              line-height: 36px;
            }
          }

          a {
            @include boston-semibold;
            color: $black-pearl;
            font-size: 17px;
            margin: 0.5rem 0;
            text-decoration: none;

            &:hover {
              color: #fff;
            }

            @media (min-width: 635px) {
              font-size: 19px;
              margin: 0.75rem 0 0;
            }
          }
        }
      }

      .disclaimer {
        @include boston-regular;
        font-size: 10px;
        line-height: 15px;
        max-width: 580px;
      }
    }
  }
}
