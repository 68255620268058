.page {
  .title-block {
    @include block-layout;
    margin-bottom: 0;
    .rich-text {
      max-width: 580px;
      h1 {
        @include title-header;
      }
      p {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  &.light {
    .title-block .rich-text {
      h1 {
        color: $black-pearl;
        @media (min-width: $mobile-breakpoint) {
          padding-bottom: 20px;
        }
      }
      p {
        color: $dim-grey;
      }
    }
  }
  // &.dark {
  //   .title-block .rich-text {
  //     h1 {
  //       color: #fff;
  //       border-bottom: 1px dashed $turquoise-blue;
  //       padding-bottom: 20px;
  //       padding-right: 57px;
  //       @media (min-width: 360px) {
  //         padding-right: 97px;
  //       }
  //       @media (min-width: 375px) {
  //         padding-right: 112px;
  //       }
  //       @media (min-width: 411px) {
  //         padding-right: 148px;
  //       }
  //       @media (min-width: 414px) {
  //         padding-right: 150px;
  //       }
  //       @media (min-width: 600px) {
  //         padding-right: 10%;
  //       }
  //     }
  //     p {
  //       color: #fff;
  //     }
  //   }
  // }
}
