.page.scenario-resources-page {
  position: relative;
  background: white url(../images/watercolour-right.jpg) no-repeat right top;
  padding: 84px 0;

  &:before {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    content: '';
    background: white url(../images/watercolour-left.jpg) no-repeat right top;
    height: 421px;
  }

  h1 {
    display: inline-block;
  }

  .title-block .rich-text {
    max-width: 900px;
  }
}

.page {
  .developSkillsBlock-wrapper {
    .developSkills-block {
      @include block-layout;
      padding-bottom: 20px;
      color: $black-pearl;
      margin-top: 60px;

      @media (min-width: $mobile-breakpoint) {
        margin-top: 40px;
        padding-bottom: 50px;
      }

      h2 {
        color: $black-pearl;
        margin-bottom: 14px;
      }

      p {
        font-size: 20px;
        line-height: 26px;
      }

      .skillList {
        font-size: 24px;
        padding-left: 0;
        margin-bottom: 40px;

        li {
          list-style: none;
          margin-bottom: 20px;
        }
      }

      .skillNumber {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50% 50%;
        margin-right: 6px;
      }

      .title-block {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .additionalResourcesBlock-wrapper {
    padding-bottom: 3rem;
    .additionalResources-block {
      position: relative;
      z-index: 2;
      @include block-layout;

      h2 {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 20px;
        color: $orange;
        text-transform: uppercase;
      }

      > p {
        font-size: 26px;
        margin-top: 0;
        @media (min-width: $mobile-breakpoint) {
          max-width: 50%;
        }
      }

      .resourceGridWrapper {
        margin-top: 40px;
      }

      .gridItem {
        background: #e6f8fa;
        color: #000;
        flex: 0 1 100%;

        @media (min-width: $mobile-breakpoint) {
          flex: 0 1 calc(50% - 15px);
        }

        &:first-of-type {
          flex: 0 1 100%;
        }

        &:hover {
          background: $turquoise-blue;
          color: $black-pearl;

          .resource-type {
            color: $black-pearl;
          }

          .resource-cta {
            svg {
              path {
                fill: $black-pearl;
              }
            }
          }
        }
      }

      .resource-title {
        font-weight: bold;
      }

      .resource-cta {
        svg {
          path {
            fill: #000;
          }
        }
      }

      .skillNumber {
        display: none;
      }

      .resource-see-more {
        color: #000;
      }
    }
  }
}
