.page {
  .skillsBlock-wrapper {
    background-color: $white;
    .skills-block {
      @include block-layout;
      padding-bottom: 30px;
      margin-bottom: 0;
      @media (min-width: $mobile-breakpoint) {
        margin-bottom: 4rem;
      }

      .rich-text {
        max-width: 580px;

        h2 {
          @include content-block-header;
          color: $black-pearl
        }

        p {
          @include content-block-paragraph;
          color: $black-pearl;
        }
      }

      .print-button {
        position: absolute;
        top: 138px;
        right: 20px;
        @media (min-width: $max-content-width + $padding * 2) {
          right: calc((100% - 1100px) / 2);
        }
        width: 52px;
        height: 52px;
        border: 1px solid $black-pearl;
        border-radius: 50%;
        text-align: center;
        @media (max-width: $mobile-breakpoint - 1px) {
          display: none;
        }
        &:hover {
          border: 1px solid $turquoise-blue;
          a {
            color: $turquoise-blue;
          }
          svg {
            path {
              fill: $turquoise-blue;
            }
          }
        }
        svg {
          width: 26px;
          height: 26px;
          margin: 10px auto 13px auto;
          display: block;
        }
        a {
          font-size: 13px;
          color: $black-pearl;
          text-decoration: none;
          text-transform: uppercase;
        }
        @media (max-width: $mobile-breakpoint - 1px) {
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
  &.dark {
    .skills-block {
      h2 {
        color: $black-pearl;
      }
      p {
        color: $black-pearl;
      }
    }
  }
  &.light {
    .skills-block {
      h2,
      p {
        color: #000;
      }
    }
  }
  .skills-block-modals {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    padding-bottom: 2rem;
    .skill-container {
      flex-grow: 1;
      flex-basis: 100%;
      @media (min-width: $mobile-breakpoint) {
        flex-basis: 50%;
      }
      @media (min-width: 900px) {
        flex-basis: 25%;
      }
    }
    .skill-tile {
      color: #fff;
      margin: 0.5rem;
      padding: 1rem;
      position: relative;
      cursor: pointer;
      @media (min-width: $mobile-breakpoint) {
        height: 178px;
      }
      h3 {
        color: #fff;
        font-size: 13px;
        @include boston-bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 0 0.5rem;
      }
      h4 {
        margin: 0 0 2rem;
        font-size: 20px;
        @include boston-semibold;
      }
      .read-more {
        position: absolute;
        bottom: 10px;
        text-transform: uppercase;
        @include boston-bold;
        font-size: 10px;
        svg {
          margin-left: 0.5rem;
          path {
            fill: #fff;
          }
        }
      }
      &:hover {
        background: rgba(95, 208, 223, 1);
        color: $black-pearl;
        h3 {
          color: $black-pearl;
        }
        .read-more {
          svg {
            path {
              fill: $black-pearl;
            }
          }
        }
      }
    }
  }
}

.skill-1 {
  background: #008178;
}

.skill-2 {
  background: #005d5b;
}

.skill-3 {
  background: #1c6a8b;
}

.skill-4 {
  background: #4d488f;
}

.skill-5 {
  background: #b47f39;
}

.skill-6 {
  background: #974a39;
}

.skill-7 {
  background: #1d3150;
}

.skill-8 {
  background: #3f205d;
}

.ReactModalPortal {
  .skills-modal {
    background-image: url(../../images/modal-watercolour.jpg);
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    max-height: 90vh;
    position: relative;
    overflow: hidden;
    .modal-header {
      padding: 1rem;
      position: relative;
    }
    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
      background: none;
      border: none;
    }
    .modal-body {
      padding: 0 2.5rem 1.5rem;
      overflow-y: auto;
      max-height: 80vh;
      @media (min-width: $mobile-breakpoint) {
        padding: 0 3rem 1.5rem;
      }
      h2 {
        font-size: 25px;
        @include boston-semibold;
        text-align: center;
      }
      .logo {
        display: block;
        margin: 2rem auto 0;
      }
    }
    p {
      @include boston-regular;
    }
    .skill-header {
      font-size: 14px;
      text-transform: uppercase;
      @include boston-bold;
      text-align: center;
      letter-spacing: 1px;
    }
    .arrow-button {
      background: none;
      border: none;
      width: 30px;
      position: absolute;
      top: 50%;
      margin-top: -21.5px;
      padding: 5px;
      &.back {
        left: 0;
      }
      &.next {
        right: 0;
      }
      svg {
        width: 16px;
        height: 29px;
        path {
          fill: $black-pearl;
        }
      }
      &:hover {
        svg {
          path {
            fill: $dark-turquoise-blue;
          }
        }
      }
    }
  }
}
