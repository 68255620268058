.page {
  .content-right-image-left-40-60-block {
    @include block-layout;

    @media (min-width: $mobile-breakpoint) {
      margin-bottom: 20px;
    }
    @media (min-width: 900px) {
      margin-bottom: 0;
    }
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;

    }
    .rich-text {
      padding: 0 20px;
      h2 {
        @include content-block-header;
      }
      p {
        @include content-block-paragraph;
      }
      @media (min-width: 900px) {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding-right: 20px;
        padding-left: 0;
        margin-bottom: 2rem;
      }
    }
    img {
      width: 100%;
      @media (min-width: 900px) {
        width: 60%;
        max-width: 100%;
        vertical-align: bottom;
      }
    }
    @media (max-width: 900px) {
      img {
        order: 1;
        margin-bottom: 20px;
      }
      .rich-text {
        order: 2;
      }
    }
  }
  &.light .content-right-image-left-40-60-block {
    .rich-text {
      h2 {
        color: #000;
      }
      p {
        color: $dim-grey;
      }
    }
  }
  &.dark .content-right-image-left-40-60-block {
    .rich-text {
      h2 {
        color: $turquoise-blue;
      }
      p {
        color: #fff;
      }
    }
  }
}
