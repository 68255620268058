.page {
  .takeSurveyBlock-wrapper {
    background-color: $white;
    color: $black-pearl;

    .takeSurvey-block {
      @include block-layout;
      display: flex;
      flex-flow: column wrap;
      gap: 7px;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 80px;
      @media (min-width: $mobile-breakpoint) {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      img {
        max-width: 103px;
      }

      h2 {
        max-width: 600px;
        text-align: left;
        font-size: 35px;
        font-weight: normal;
        text-align: center;
      }
      button {
        margin-right: auto;
        margin-left: auto;
        color: $black-pearl;
        cursor: pointer;
      }
    }
  }
}
