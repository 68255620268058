.skillsListBlock-wrapper {
  @media (min-width: $mobile-breakpoint) {
    background-color: $black-pearl;
  }
  .skills-list-block {
    @include block-layout;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    @media (min-width: $mobile-breakpoint) {
      padding-top: 45px;
      padding-bottom: 45px;
      margin-bottom: 120px;
    }

    .skills-list-block-skills {
      column-count: 1;
      column-gap: 40px;
      margin-bottom: 75px;
      @media (min-width: $mobile-breakpoint) {
        column-count: 2;
        margin-bottom: 25px;
      }
      @media (min-width: 900px) {
        column-count: 3;
      }

      .skills-list-block-skill {
        break-inside: avoid-column;
        display: flex;
        min-width: 100px;
        flex-wrap: nowrap;
        text-decoration: none;
        border-bottom: 1px dashed #b3b3b3;

        h3 {
          @include boston-bold;
          font-size: 16px;
          color: $black-pearl;
          background-color: $turquoise-blue;
          text-transform: uppercase;
          padding: 0 5px;
          text-align: center;
          min-width: 72px;
          margin: 15px 0;
          line-height: 25px;
          align-self: flex-start;
        }

        h4 {
          @include content-block-paragraph;
          margin: 15px 0 15px 15px;
          line-height: 25px;
          color: $black-pearl;
          @media (min-width: $mobile-breakpoint) {
            color: #fff;
          }
        }
      }
    }
  }
}
