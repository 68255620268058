.ReactModalPortal {
  .modal {
    border: 2px solid #fff;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    max-width: 538px;
    margin-left: $padding;
    margin-right: $padding;
  }
  .close-modal {
    svg {
      cursor: pointer;
      &:hover {
        path {
          transition: all 0.3s;
          fill: $dark-turquoise-blue;
        }
      }
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(19,30,41, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }


}



.ReactModalPortal {
  .modal.survey-modal {
    max-width: 700px;
    width: 100vh;
    .modal-header {
      background: $orange;
      padding: 1rem;
      position: relative;
      h2 {
        text-align: center;
        font-size: 1.75rem;
        margin: 20px;
        @include boston-semibold;
      }
    }
    .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
      background: none;
      border: none;
    }
    .modal-body {
      padding: 0;
      text-align: center;
      @include boston-regular;
      p {
        margin-top: 0;
        font-size: 1.063rem;
      }
      .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        button, a {
          margin: 0.5rem;
        }
        a {
          @extend button.primary;
          text-decoration: none;
          line-height: 1.15;
        }
      }
    }
    .scroll-wrapper {
      // scroll-wrapper needed for ios support
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      width: 100%;
      height: 50vh;
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}

