.choose-page {
  min-height: calc(100vh - 55px);
  text-align: center;
  padding: $padding;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    background-image: url('../../images/splash-bottom.jpg');
    background-color: $white;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-size: 100% 50%; 
    background-position: left bottom;
    top: 0;
    left: 0;
    position: absolute;
  }

  &.concern {
    background: #EFFAFC;
    min-height: calc(100vh - 100px);
    &:before {
      content: '';
      height: 100%;
      width: 100%;
      background-image: url('../../images/splash-bottom.jpg');
      transform: scaleX(-1);
      background-color: #EFFAFC;
      background-repeat: no-repeat;
      background-blend-mode: multiply;
      background-size: 100% 50%; 
      background-position: right bottom;
      top: 0;
      left: 0;
      position: absolute;
      @media (min-width: 768px) {
        background-size: 100% 20%; 
      }
      @media (min-width: 1024px) {
        background-size: 100% 40%; 
      }
    }
  }
  .choose-content {
    position: relative;
    margin: 0 auto;
    max-width: 1100px;
    @media (max-width: $max-content-width + ($padding * 2) - 1) {
      margin: 0;
    }
  }
  .heading {
    color: $dark-blue;
    position: relative;
    @include dm-serif-display;
    font-size: 2.25rem;
    text-align: left;
    line-height: 1;
    @media (min-width: $mobile-breakpoint) {
      font-size: 2.75rem;
      text-align: center;
      margin-top: 2.5rem;
    }
    &.concern-page {
      margin-bottom: 1.5rem;
    }
  }
  .instructions {
    color: $turquoise-blue;
    font-size: 1rem;
    text-align: left;
    @media (min-width: $mobile-breakpoint) {
      font-size: 1.25rem;
      text-align: center;
    }
  }
  .choices {
    max-width: $max-content-width + 30;
    margin: 0 auto;

    @media (min-width: $mobile-breakpoint) {
      display: flex;
      justify-content: center;
      margin: 3rem auto 1rem;
    }

    @media (min-width: 768px) {
      align-items: flex-end;
    }

    &.youth {
      display: grid;

      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill,minmax(400px, 1fr));
        column-gap: 2rem;
        justify-items: center
      }

      .cta-block {
        width: 75%;
        margin-left: 20px;
      }
    }

    .role-image {
      display: flex;
      justify-content: center;
      svg#check-square {
        width: 15%;
        margin-right: 1rem;
      }
      .illustration-image {
        width: 75%;
      }
    }

    .choice {
      background: transparent;
      border-radius: 0.25rem;
      border: none;
      margin: 1.5rem 0;
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      position: relative;

      @media (min-width: $mobile-breakpoint) {
        margin: 0 1% 2rem;
        flex-direction: column;
        align-items: stretch;
        flex: 1 2 40%;
      }
      
      .text {
        @include boston-regular;
        font-size: 0.875rem;
        border: $aqua-blue 1px solid;
        border-radius: 4px;
        width: 100%;
        align-items: flex-start;
        background-color: $white;
        @media (min-width: $mobile-breakpoint) {
          font-size: 1.2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 1rem;
        }

        .role-title {
          margin: auto;
          background-color: $white;
          @media (min-width: $mobile-breakpoint) {
            font-size: 0.575rem;
          }
          @media (min-width: 768px) {
            font-size: 0.85rem;
          }
          @media (min-width: 1024px) {
            font-size: 18px;
          }
        }

        .name {
          @include boston-regular;
          margin-top: 1rem;
          display: block;
          font-weight: bold;
          margin-left: 20px;
          color: #000;
          font-size: 28px;
          line-height: 28px;
        }

        .description {
          margin: 12px;
          height: 6rem;

          @media (min-width: 768px) {
            height: 8rem;
          }

          @media(min-width: 900px) {
            height: 12rem;
          }

          @media(min-width: 1024px) {
            height: 10rem;
          }

          @media(min-width: 1200px) {
            height: 9.5rem;
          }
        }

        &.concern-page-container {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          border: none;

          @media (min-width: 900px) {
            flex-direction: row;
          }

          .text.concern-page {
            background-color: $white;
            border: none;
            text-align: justify;
            align-items: left;
  
            .description {
              margin-top: 6px;
              margin-left: 20px;
              margin-right: 20px;
              margin-bottom: 7px;
            }
  
            .description-bullets {
              margin: 20px 20px 20px 35px;
              text-align: justify;
            }

            @media (min-width: 900px) {
              width: 70%;
              margin-left: 1.5rem;
              margin-top: 10px;
            }
          }

          .concern-image {
            margin-top: 1rem;
            width: 25%;

            @media (min-width: 768px) {
              margin-top: 2rem;
            }

            @media (min-width: 900px) {
              margin-top: 0;
              margin-right: 1rem;
              width: 25%;
            }

            img {
              width: 100%;
            }
          }
        }
      }

      &.youth {
        background-color: $white;
        align-self: stretch;
        align-items: center;
        border: transparent 1px solid;

        .darkblue-right-arrow {
          border: 1px $aqua-blue solid;
          text-align: center;
        }

        &:hover {
          transition: 0.2s ease;
          border: 1px $aqua-blue solid;
          
          .begin-story-button {
            border: $dark-blue 1px solid;
            transition: 0.2s ease;
          }

          .darkblue-right-arrow {
            background-color: $dark-blue;
            border: $dark-blue 1px solid;
            transition: 0.2s ease;
  
            #youth-arrow {
              fill: $white;
              transition: 0.2s ease;
            }
          }
        }
      }



      &.role-choice {
        flex-basis: 0;
        flex-grow: 1;
        margin-bottom: 3rem;

        &:hover {
          img  {
            transform: scale(1.1);
            transition: .4s;
          }
          #check-in-circle {
            stroke: #000;
            stroke-width: 3;
          }
        }

        &.active {
          #check-in-circle {
            stroke: #000;
            stroke-width: 3;
          }
          #bg-blue {
            fill: #FF9F01;
          }
          .text {
            border: #FF9F01 1px solid;
          }
        }

        img {
          width: 75%;

          @media (min-width: $mobile-breakpoint) {
            width: 65%;
          }
        }

        .text {
          display: flex;
          flex-direction: row;
          text-align: center;
          text-transform: uppercase;
          margin-top: 2rem;
        }
      }
      .arrow-select {
        background: $turquoise-blue;
        width: 41px;
        height: 44px;
        border-radius: 0 0.25rem 0.25rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: $mobile-breakpoint) {
          width: 100%;
          height: 48px;
          border-radius: 0;
          background: #ccc;
        }
        .select {
          display: none;
          @media (min-width: $mobile-breakpoint) {
            display: block;
            @include boston-semibold;
            font-size: 1.125rem;
            text-transform: uppercase;
          }
        }
        .arrow {
          @media (min-width: $mobile-breakpoint) {
            display: none;
          }
        }
      }
    }
  }
  .logo {
    position: relative;
    z-index: 20;
    @media (max-width: 320px) {
      margin-top: 70px;
    }
    @media (max-width: $mobile-breakpoint - 1) {
      margin-top: 100px;
    }
    @media (min-width: 1024px) {
      margin-top: 5rem;
    }
  }

  // Consent checkbox
  .consent {
    position: relative;
    padding: $padding * 2;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
      flex-basis: 48%;
      width: 50%;
      text-align: center;
      margin-top: -5rem;
    }
    @media (min-width: $max-content-width) {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 45%;
    }
    .checkbox-container {
      margin-top: 1.5rem;
      position: relative;
      @media (min-width: $max-content-width) {
        margin: 2rem 0;
      }
      label {
        position: relative;
        display: block;
        text-align: left;
        margin-left: 2rem;
        cursor: pointer;
        &:before, &:after {
          position: absolute;
        }
        &:before {
          content: "";
          display: inline-block;
          height: 18px;
          width: 18px;
          border: 1px solid $turquoise-blue;
          border-radius: 3px;
          left: -30px;
          top: 1px;
          @media (min-width: 768px) {
            top: 5px;
          }
        }
        &:after {
          content: "";
          left: -27px;
          top: 1px;
          @media (min-width: 768px) {
            top: 4px;
          }
        }
      }
      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        &:checked {
          + label {
            &:before {
              background: #fff;
            }
            &:after {
              content: url(../../images/black-checkmark.svg);
            }
          }
        }
      }

    }
    .error {
      color: $orange;
      margin-bottom: 0;
      width: 100%;
    }

    .cta-block {
      .begin-story-button:not(.outline):hover {
        border: 1px solid $dark-blue;
        .darkblue-right-arrow {
          background-color: $dark-blue;
          transition: 0.2s ease;
    
          #consent-arrow {
            fill: $white;
            transition: 0.2s ease;
          }
        }
      }
    }
  }
}
