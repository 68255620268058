* {
  box-sizing: border-box;
  margin: 0;
  padding:0;
}

.rich-text {
  > *:empty {
    display: none;
  }
  a {
    color: $dark-turquoise-blue;
    &:hover {
      color: darken($dark-turquoise-blue, 10%);
    }
  }
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

a.button {
  img {
    width: 22px;
    height: 22px;
    vertical-align: bottom;
    margin-left: 10px;
  }
}

button {
  cursor: pointer;
}

.illustration-image {
  img {
    width: 100%;
  }
}

html {
  overflow-y: scroll;
}
