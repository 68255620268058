.page {
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 60% auto;
  @media (min-width: $mobile-breakpoint) {
    background-size: 50% auto;
  }

  .page-content .page-title-wrapper {
    @include block-layout;
    .page-title {
      @include page-title;
    };
  }

  &.top-padding {
    padding: 20px 0;
    @media (min-width: $mobile-breakpoint) {
      padding: 75px 0;
    }
    .content-blocks .block-wrapper:last-child {
      margin-bottom: -20px;
      @media (min-width: $mobile-breakpoint) {
        margin-bottom: -75px;
      }
    }
  }

  .page-content {
    .block-wrapper {
      margin: 0 auto;
      &.page404 {
        max-width: $max-content-width + ($padding * 2);
        padding: 0 $padding;
      }
    }

    &.homepage {
      .info-block {
        p:last-child {
          color: $turquoise-blue;
          padding-top: 1rem;
        }
      }
    }
  }

  &.light {
    background-color: #fff;

    &.resources-page {
      position: relative;
      
      .hero-desktop-background {
        height: 300px;
        background-position-y: 50%;
        background-position-x: right;
        background-color: #F0FAFC;
        background-blend-mode: multiply;
      }
    }

    &.resources-page:before {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      content: '';
      background: url(../images/splash-bottom.jpg) no-repeat right top;
      height: 421px;
      mix-blend-mode: multiply;
      width: 30%;
      transform: scaleY(-1) scaleX(-1);
    }
  }
}
