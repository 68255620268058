.instructions-page {
  background: transparent;
  min-height: calc(100vh - 100px);
  color: $dark-blue;
  font-size: 1.063rem;
  position: relative;
  
  @media (min-width: 768px) {
    font-size: 1.375rem;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    background-image: url('../../images/splash-bottom.jpg');
   //background-color: $white;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-size: 40% 10%; 
    background-position: left bottom; // not sure why this is backwards from what we want (want the image to be at the top right)
    transform: rotate(180deg);
    top: 0;
    left: 0;
    position: absolute;

    @media (min-width: 1440px) {
      background-size: 40% 20%;}
  }

  .page-content {
    z-index: 1;
  }

    h1 {
      @include dm-serif-display;
      margin-top: 0;
    }
    .container {
      max-width: $max-content-width;
      padding: $padding;
      margin: 0 auto;
      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
      }
      @media (min-width: $max-content-width + 40) {
        padding: $padding 0;
      }
      .intro-info-boxes {
        position: relative;
        text-align: center;
        margin-top: 1rem;
        @media (min-width: 768px) {
          margin: 1.5rem 3rem 0 3rem;
        }
  
        .instructions-illustration {
          .illustration-image {
            width: 50%;
            margin: auto;
            margin-bottom: 1rem;
  
            @media (min-width: $mobile-breakpoint) {
              width: 30%;
            }
  
            @media (min-width: 1024px) {
              width: 22%;
            }
          }
        }
        .instructions-intro-text {
          max-width: 90%;
          margin: auto;
          @media (min-width: 768px) {
            max-width: 100%;
          }
          @media (min-width: 1024px) {
            max-width: 70%;
          }
          p {
            @include merriweather-regular;
            font-size: 16px;
            @media (min-width: 768px) {
              font-size: 20px;
            }
            @media (min-width: 1024px) {

            }
          }
        }
        .instructions-time-text {
          max-width: 90%;
          margin: auto;
          margin-top: 1rem;
          margin-bottom: 1rem;
          @media (min-width: 768px) {
            max-width: 100%;
          }
          @media (min-width: 1024px) {
            max-width: 85%;
          }
          p {
            @include merriweather-regular;
            font-size: 16px;
            @media (min-width: 768px) {
              font-size: 20px;
            }
            @media (min-width: 1024px) {

            }
          }
        }
      }
      .disclaimer-block {
        p {
          @include boston-regular;
          text-align: center;
          width: 90%;
          margin: auto;
          color: $dark-blue;
          font-size: 15px;
          margin-top: 4rem;
          margin-bottom: 4rem;
        }
      }
    }
    .block-with-icon {
      display: flex;
      margin: 1rem 0;
      .icon {
        flex-basis: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 768px) {
          flex-basis: 60px;
          margin-right: 2.5rem;
        }
        p {
          margin: 0.25rem 0 0;
          @include boston-bold;
          text-transform: uppercase;
          max-width: 4rem;
          text-align: center;
        }
      }
      .info {
        padding: 0 1rem;
        @include merriweather-regular;
        p {
          margin: 0;
        }
      }
    }
  }

