.socialBlock-wrapper {
  background: #f0fafc;
  padding-bottom: 1rem;
  @media (min-width: $mobile-breakpoint) {
    padding-bottom: 0;
  }
  .social-block {
    @include block-layout;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: $max-content-width + $padding * 2 - 1) {
      padding: 30px 20px;
    }
    padding: 50px 0;
    h2 {
      @include boston-semibold;
      font-size: 24px;
      line-height: 26px;
      padding: 0 20px;
      margin: 0 auto 1.5rem;
      text-align: center;
      @media (min-width: $max-content-width) {
        font-size: 46px;
        line-height: 50px;
        max-width: 400px;
        text-align: left;
        padding: 0;
        margin: 0;
      }
    }

    .social-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media (min-width: $max-content-width) {
        width: auto;
      }
      h3 {
        text-align: center;
        margin: 0 0 0.5rem;
      }
    }

    // add this overrides
    .addthis_inline_share_toolbox {
      width: 100%;
      // padding: 0 3rem;
      @media (min-width: $max-content-width) {
        width: auto;
      }

      table {
        width: 100%;
      }

      tr {
        @media (min-width: $max-content-width) {
          display: flex;
        }
      }

      .at4-count-container {
        display: flex;
        border: none;
        flex-direction: column;
        margin-bottom: 1rem;

        .at4-count {
          color: $turquoise-blue !important;
        }
      }

      .at4-share-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: -20px;
        margin-right: -20px;
        @media (min-width: 360px) {
          margin: 0;
        }
        .at-svc-email {
          display: none;
        }
      }

      .at-resp-share-element {
        .at-share-btn {
          @media (min-width: $max-content-width) {
            margin: 2px 8px;
          }
        }
      }
    }
  }
}
