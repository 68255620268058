.page {
  .hero-block {
    background-color: #fff;
    .hero-block-content {
      @include block-layout;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (min-width: $mobile-breakpoint) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      @media (min-width: 900px) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      min-height: 500px;
      text-align: center;
      .logo {
        margin: 35px auto 55px;
      }
      h1 {
        @include title-header;
        color: $black-pearl;
        text-align: center;
        max-width: 850px;
      }
      h2 {
        font-size: 1.125rem;
      }
      .button {
        display: inline-block;
        margin-top: 20px;
        @media (min-width: $mobile-breakpoint) {
          margin-top: 0;
        }
      }

      @media (min-width: $mobile-breakpoint) {
        display: block;
        text-align: left;
        min-height: 700px;
        h1 {
          text-align: left;
          padding-bottom: 0;
        }
        h2 {
          font-size: 1.75rem;
          margin-bottom: 2.5rem;
        }
        .logo {
          margin: 0 0 38px 60px;
        }
      }

      @media (min-width: $max-content-width) {
        h1 {
          padding-right: 20%;
          font-size: 70px;
          line-height: 70px;
        }
        h2 {
          padding-right: 43%;
          font-size: 1.9rem;
          line-height: 1.2;
        }
      }

      .learn-more {
        color: $black-pearl;
        text-align: center;
        text-transform: uppercase;
        padding: $padding 24px;
        display: inline-block;
        margin: 70px 0 20px;
        text-decoration: none;
        @media (min-width: $mobile-breakpoint) {
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0.5em;
          font-size: 0.75rem;
          @media (min-width: $mobile-breakpoint) {
            font-size: 1rem;
          }
        }
        svg {
          transform: translateY(0);
          transition: all 0.3s;
        }
        &:hover {
          color: $dark-turquoise-blue;
          transition: all 0.3s;
          svg {
            transform: translateY(0.6rem);
            transition: all 0.3s;
            path {
              fill: $dark-turquoise-blue;
              transition: all 0.3s;
            }
          }
        }
      }
    }
    &.light .hero-block-content h1 {
      color: $black-pearl;
    }
  }
}

.hero-desktop-background,
.hero-mobile-background {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
}

.hero-mobile-background {
  background-position: center bottom;
  background-position-y: -100%;

  @media (min-width: $mobile-breakpoint) {
    background-size: contain;
    background-position-y: -50%;
  }

  @media (min-width: 414px) {
    background-position-y: 0;
  }
}

.hero-desktop-background {
  background-size: 0;
  transition: background-position 0.5s cubic-bezier(0, 1, 0.5, 1);
}

@media (min-width: $mobile-breakpoint) {
  .hero-desktop-background {
    background-size: contain;
    background-position: right bottom;
  }
  .hero-mobile-background {
    background-size: 0;
  }
}

// Hero not on homepage styling
.content-blocks {
  .heroBlock-wrapper:not(:first-child) {
    .logo {
      display: none;
    }
    .hero-block-content {
      @media (min-width: $mobile-breakpoint) {
        min-height: 600px;
      }
    }
  }
}

.hero-buttons {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  width: 50%;
  margin: auto;
  align-content: center;
  @media (min-width: 768px) {
    width: 100%;
  }

  .begin-story-button {
    margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 14px;
    @include boston-semibold;
    color: $dark-blue;
    background-color: $white;
    white-space: nowrap;
    padding-right: 0;
    border: $aqua-blue 1px solid;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 0.875rem;
    text-decoration: none;

    @media (min-width: 768px) {
      margin-top: 0;
      font-size: 1.063rem;
    }

    .darkblue-right-arrow {
      background-color: $aqua-blue;
      padding: 1rem;
      width: 100%;
      margin-left: 14px;
    }
  }

  .begin-story-button:hover {
    border: 1px solid $dark-blue;
    
    .darkblue-right-arrow {
      background-color: $dark-blue;
      transition: 0.2s ease;

      #hero-arrow {
        fill: $white;
        transition: 0.2s ease;
      }
    }
  }

  @media (min-width: $mobile-breakpoint) {
    flex-flow: row nowrap;
  }
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
  .hero-btn:hover {
    transition: all .3s;
    background: #131e29;
    color: #fff;
  }
}


