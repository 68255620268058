.resourceGridWrapper {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  button {
    flex: 0 1 initial;
    color: $black-pearl;
    margin: 70px auto 80px;
    cursor: pointer;
  }
}

.resourceGrid {
  display: flex;
  flex-flow: row wrap;

  .gridItem {
    display: flex;
    flex-flow: column wrap;
    flex: 0 1 100%;
    background: rgba(95, 208, 223, 0.17);
    color: $black-pearl;
    margin-bottom: 15px;
    padding: 20px;
    text-decoration: none;

    @media (min-width: $mobile-breakpoint) {
      flex: 0 1 calc(50% - 15px);
      margin-right: 15px;
    }

    @media (min-width: 1024px) {
      flex: 0 1 calc(25% - 15px);
    }

    &:hover {
      background: $turquoise-blue;
      color: $black-pearl;

      .resource-type {
        color: $black-pearl;
      }

      .resource-cta {
        svg {
          path {
            fill: $black-pearl;
          }
        }
      }
    }
  }

  .resource-type,
  .resource-title,
  .resource-description {
    display: block;
  }

  .resource-type {
    text-transform: uppercase;
    color: $orange;
    margin-bottom: 15px;
    display: block;
    font-size: 14px;
    line-height: 24px;
  }

  .resource-title {
    font-size: 18px;
    line-height: 23px;
  }

  .resource-description .rich-text p {
    font-size: 15px !important;
    line-height: 19px;
    font-weight: normal;
  }

  .resource-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;

    div:first-child {
      flex: 0 1 70%;
    }
  }

  .skillNumber {
    margin-top: 7px;
    width: 28px;
    height: 28px;
  }

  .resource-cta {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    font-size: 12px;
    font-weight: bold;

    svg {
      margin-left: 7px;
      path {
        fill: #fff;
      }
    }
  }
}
