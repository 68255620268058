footer {
  display: flex;
  flex-direction: column;

  @media (min-width: $mobile-breakpoint) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  @media (min-width: $max-content-width + $padding * 2) {
    padding-left: calc((100% - 1100px) / 2);
    background-color: #4d4d4d;
  }
  height: 300px;
  .footer-links {
    width: 100%;
    background-color: #4d4d4d;
    @media (min-width: $mobile-breakpoint) {
      width: 52%;
    }
    .footer-links-content {
      padding: $padding;
      margin-left: 0;
      text-align: center;

      @media (min-width: $mobile-breakpoint) {
        padding: 55px $padding 0 20px;
        text-align: left;
      }
      @media (min-width: $max-content-width + $padding * 2) {
        padding: 55px $padding 0 0;
      }
      h1 {
        @include boston-bold;
        color: $turquoise-blue;
        font-size: 20px;
        line-height: 38px;
        text-transform: uppercase;
        margin: 0;
      }

      a {
        display: block;
        text-decoration: none;
        font-size: 15px;
        line-height: 30px;
        text-transform: uppercase;
        // color: #b3b3b3;
        color: $turquoise-blue;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .footer-social-impact {
    width: 100%;
    background-color: $united-way-red;
    padding: $padding;
    text-align: center;
    @media (min-width: $mobile-breakpoint) {
      padding: 55px $padding;
      width: 22%;
    }
    img {
      width: 100%;
      max-width: 150px;
      @media (min-width: 900px) {
        width: 70%;
        margin-top: -3rem;
      }
    }
  }
  .footer-united-way {
    width: 100%;
    background-color: #fff;
    padding: $padding;
    text-align: center;
    @media (min-width: $mobile-breakpoint) {
      padding: 55px $padding;
      width: 28%;
    }
    img {
      width: 100%;
      margin-bottom: 10px;
      max-width: 330px;
      @media (min-width: 900px) {
        width: 70%;
      }
    }
    .rich-text {
      p {
        font-size: 12px;
        line-height: 18px;
        margin: 0;
      }
    }
  }
}

.footer-wrapper {
  position: relative;
  .back-to-top {
    text-transform: uppercase;
    font-size: 0.8rem;
    border: none;
    background: none;
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);
    left: 50%;
    padding-bottom: 0.5rem;
    @include boston-semibold;

    svg {
      width: 13px;
      height: 14px;
      display: block;
      margin: 0 auto 5px;
    }
    @media (min-width: $mobile-breakpoint) {
      display: none;
    }
  }
}
